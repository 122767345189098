<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <orisis-logo-full />
        </b-link>

        <b-card-title class="mb-1">
          Modifier votre mot passe 🔒
        </b-card-title>
        <!-- form -->
        <validation-observer ref="changePasswordForm">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <b-form-group
              label="Email"
              label-for="forgot-password-email"
              class="mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Adresse e-mail"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userData.email"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="email@domaine.fr"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <!-- password -->
            <b-form-group
              label="Ancien mot de passe"
              label-for="reset-password-new"
              class="mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="OldPassword"
                vid="OldPassword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-old"
                    v-model="userOldPassword"
                    :type="password0FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-old"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password0ToggleIcon"
                      @click="togglePassword0Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <vs-divider></vs-divider>

            <!-- password -->
            <b-form-group
              label="Nouveau mot de passe"
              label-for="reset-password-new"
              class="mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="userNewPassword"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger" v-if="errors[0]">Doit contenir au moins 8 caractères dont : 1 majuscule, 1 minuscule, 1 chiffre, et 1 caractère spécial</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmer votre mot de passe"
              class="mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="userConfirmPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger" v-if="errors[0]">Confirmation invalide</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              Enregistrer mon nouveau mot de passe
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="'/login'">
            <feather-icon icon="ChevronLeftIcon" /> Retour à la connexion
          </b-link>
        </p>

      </b-card>

    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from 'vee-validate';
import { required } from "@validations";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
configure({
  generateMessage: localize('fr', {
    messages: {
        url: 'Ce champ doit être une URL',  
        required: 'Ce champ est requis',
        email: 'Ce champ doit être un email',
      
    },
  }),
});
localize('fr')


import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import OrisisLogoFull from "@core/layouts/components/LogoFull.vue";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    OrisisLogoFull,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('login'))?.login?.userInfo,
      userOldPassword: '',
      userNewPassword: '',
      userConfirmPassword: '',
      userId: '',
      required,
      // Toggle Password
      password0FieldType: 'password',
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password0ToggleIcon() {
      return this.password0FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods:{
    togglePassword0Visibility() {
      this.password0FieldType = this.password0FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      var payload = {
        oldPassword: this.userOldPassword,
        newPassword: this.userNewPassword,
        confirmPassword:  this.userConfirmPassword,
        userId: this.userData.id,
      }
      var that = this;

      this.$refs.changePasswordForm.validate().then((success) => {
        if (success) {
         this.$store.dispatch('changePassword', {payload:payload, that: that})
        }
      });
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
